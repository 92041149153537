body {
	font-family: 'Quicksand', sans-serif;
	color: #745c4a;
	background-color: #e8dac4;
	margin: 0;
}

.App {
	min-height: 100vh;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	width: 100%;
}

.card {
	display: flex;
	flex-direction: column;
	background-color: #745c4a22;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	max-width: 500px;
	width: 80%;
}

.logo {
	text-align: center;
}

.logo h1 {
	margin: 0;
	color: #745c4a;
	font-size: 2em;
}

.desc {
	margin-top: 2rem;
	text-align: center;
}

.location {
	text-align: center;
}

.location p {
	color: #745c4a;
	margin-top: 0;
	font-size: 1em;
}

.desc p {
	color: #745c4a;
	font-size: 1.2em;
}

label {
	color: #745c4a;
	font-size: 1.2em;
}

input {
	border: 1px solid #745c4a;
	border-radius: 0.5rem;
	font-size: 1.5rem;
	margin: 0.25rem 0;
	padding: 0.5rem;
	width: calc(100% - 1rem);
}

.logo-container {
	display: flex;
	justify-content: center;
}

.logo {
	max-width: 400px;
}

button {
	background-color: #745c4a;
	border: none;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 1.2em;
	margin: 5px 0;
	padding: 10px;
	width: 100%;
}

button:hover {
	background-color: #5c4837;
}

button:active {
	background-color: #3e2f23;
}

.footer {
	text-align: center;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-top: 2.5rem;
	height: 60px;
}

.footer p {
	color: #745c4a;
	font-size: 1rem;
}

a {
	color: #5c4837;
}

p {
	margin: 0;
}
